import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { ErrorHandlerService } from "./error-handler.service";

@Injectable({
  providedIn: "root"
})
export class CommunicatorService {
  public httpOptions: any;
  public httpOptionsBlob: any;
  public httpUnauthenticatedOptions: any;

  constructor(private http: HttpClient, private errorHandlerService: ErrorHandlerService) {
    this.createHttpOptions();
  }

  createHttpOptions() {
    this.httpUnauthenticatedOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        // "Access-Control-Allow-Credentials": "true",
        Accept: "application/json",
        responseType: "arraybuffer"
      }),
      withCredentials: true
    };
    this.httpOptionsBlob = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      responseType: "blob" as "json",
    };
  }

  refreshHttpHeaders() {
    this.createHttpOptions();
  }

  public getData<T>(url, params?): Observable<any | T> {
    return this.http.get(url, { params: params }).pipe(
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  public postData<T>(url, requestedData, params?): Observable<any | T> {
    return this.http.post(url, JSON.parse(requestedData), { params: params }).pipe(
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  public postUnauthenticatedData(url, requestedData): Observable<any> {
    return this.http.post(url, requestedData, this.httpUnauthenticatedOptions).pipe(
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  public putUnauthenticatedData(url, requestedData): Observable<any> {
    return this.http.put(url, requestedData, this.httpUnauthenticatedOptions).pipe(
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  public updateData(url, updatedData, params?): Observable<any> {
    const data = JSON.parse(updatedData);
    return this.http.put(url, data, { params: params }).pipe(
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  public deleteData(url): Observable<any> {
    return this.http.delete(url, this.httpOptions).pipe(
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  public fileUpload(url, fileToUpload: any) {
    return this.http.put(url, fileToUpload, this.httpOptions).pipe(
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  public getPDF(url): Observable<any> {
    return this.http.get<any>(url, this.httpOptionsBlob).pipe(
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  public patchData(url, data, params?): Observable<any> {
    return this.http.patch(url, JSON.parse(data), { params: params }).pipe(
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  public getExcelData(url, params?): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      responseType: 'blob' as 'json', // Specify the response type as blob
      params: params
    };
    return this.http.get(url,httpOptions).pipe(
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }
}
