import {Inject, Injectable} from "@angular/core";
import {throwError} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";

@Injectable({
  providedIn: "root"
})
export class ErrorHandlerService {

  constructor(public router: Router, private dialog: MatDialog) { }

  handleError(error) {
    let errorMessage: any;
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = {statusCode: error.status, message: error.error};
      if (errorMessage.statusCode === 403 && errorMessage !== undefined && errorMessage !== null &&
        errorMessage.message.detail !== undefined &&
        errorMessage.message.detail !== null && (errorMessage.message.detail === 'Authentication credentials were not provided.' ||
        errorMessage.message.detail === 'CSRF Failed: CSRF token missing or incorrect.')) {
        this.dialog.closeAll();
        
        if(localStorage.getItem('isExt') === '1'){
          this.router.navigate(['/error'], {queryParams: {msg: errorMessage.message.detail}})
        }else{
          this.router.navigate(['/session/signin']);
        }
      }
    }
    return throwError(errorMessage);
  }
}
