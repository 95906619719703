export const environment = {
  production: false,
  ssoUrl: "",
  athenaEhrUrl: "https://preview.athenahealth.com/",
  BACKEND_URL: "https://api.app-qa.eclat.payer-platform.com/",
  SAML_SESSION_COOKIE_NAME: "dev_saml_session",
  SESSION_COOKIE_NAME: "dev_sessionid",
  CSRF_COOKIE_NAME: "dev_csrftoken",
  COOKIE_DOMAIN: ".evaire-qa.eclat.payer-platform.com",
};
