import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { DataService } from "../../common/services/data.service";
import { IChart } from "../../coding/Models/chart";
import { LocationStrategy } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import {
  CodingGuidelinesDialogComponent
} from "../../education/coding-guidelines-dialog/coding-guidelines-dialog.component";
import { EducationDialogComponent } from "../../education/education-dialog/education-dialog.component";
import { MenuComponent, MenuItemModel, BeforeOpenCloseMenuEventArgs } from "@syncfusion/ej2-angular-navigations";
import { interval, Observable, Subscription } from "rxjs";
import { Temp } from "src/app/concurrent/constants/concurrent-constants";
import {
  AlertsResource,
  API_CURRENT_VERSION,
  CoderRole,
  CodingMasterExclusionInProgress,
  CodingProfileResource,
  CodingRejectInProgress,
  CodingReviewResource,
  EducationResource,
  EndPointBaseUrl,
  FeedbackQueuesResource,
  GuidelineResource,
  L1AuditResource,
  L2AuditResource,
  L3AuditResource,
  ProviderResource,
  QAL1Role,
  QAL2Role,
  QAL3Role,
  RejectQueuesResource,
  UserManagementResource,
  ChartRepositoryResource,
  DashboardResource,
  ReportingResource,
  AdminResource,
  EditChartResource,
  ResetFunctionalityResource,
  SamplingManagementResource,
  WorkQueueManagementResource,
  SessionExpiredTime,
  SessionExtendTime,
  StandardReportsResource,
  ManagerReportsResource,
  UserProductivityReport,
  CustomReportsResource,
  ExclusionQueuesResource,
  EPP_HB_MONITOR_TIME_INTERVAL,
  EPP_HB_MONITOR_OFFSET_TIME,
  ProviderQueriesResource,
  ProvidersDashboardResource,
  Non_EHR_Dashboard,
  ProviderRole,
  AthenaResetChartEvents,
  RoleStringToIdMapping,
  ProductOwner,
  BlindCoding,
  CodeDataValidation,
  VendorAudit,
  Concurrent,
  CommonAlertStatus
} from "../../common/settings/settings";
import {CommunicatorService} from "../../common/services/communicator.service";
import {SelectClientService} from "../../client/management/select-client/select-client.service";
import {ListClientsService} from "../../client/management/list-clients/list-clients.service";
import {IClient} from "../../client/management/model/client";
import {MailboxService} from "../../mailbox/mailbox.service";
import {ConfigurationService} from "../../common/services/configuration.service";
import {ListAlertsService} from "../../alerts/management/list-alerts/list-alerts.service";
import {UserService} from "../../user/user.service";
import {ModifyEducationComponent} from "../../education/management/modify-education/modify-education.component";
import {ModifyUserProfileComponent} from "../../user/management/modify-user-profile/modify-user-profile.component";
import {CommonDialogComponent} from "../../common/component/common-dialog/common-dialog.component";
import {NotificationService} from "../../common/services/notification.service";
import {ErrorMessageService} from "../../common/services/error-message.service";
import {AutoResume, DEFAULT_INTERRUPTSOURCES, Idle} from "@ng-idle/core";
import {SessionExpiredComponent} from "../../session-expired/session-expired.component";
import {Keepalive} from "@ng-idle/keepalive";
import {ReportsService} from "../../reports/reports.service";
import {SystemClientsService} from "../../common/services/system-clients.service";
import {TimezoneService} from "../../common/services/timezone.service";
import {htmlAstToRender3Ast} from "@angular/compiler/src/render3/r3_template_transform";
import { ExtensionService } from "src/app/athena-one-integration/shared/service/extension.service";
import { environment } from "src/environments/environment";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { CookieService } from "ngx-cookie";
import { filter } from "rxjs/operators";
import { SelectProjectService } from "src/app/project/management/select-project/select-project.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, OnDestroy {
  public clientIdAvailable = true;
  selectedClientName = null;
  public alertsList;
  public chartData: IChart;
  public hideIcons = false;
  public pdfWindow: Window;
  @ViewChild("menu")
  public menuObj: MenuComponent;
  public menuItems: MenuItemModel[] = [
    {
      iconCss: "e-icons e-menu",
      items: [
        {
          text: "Admin",
          id: "admin",
          items: [
            { text: "Education Management", id: EducationResource.toString() },
            { text: "Guidelines Management", id: GuidelineResource.toString() },
            { text: "Provider Management", id: ProviderResource.toString() },
            { text: "User Management", id: UserManagementResource.toString() }
          ]
        },
        { text: "Alerts", id: AlertsResource.toString() },
        { text: "Chart Repository", id: ChartRepositoryResource.toString() },
        { text: "Coder", id: CodingReviewResource.toString() },
        { text: "Coding Profile", id: CodingProfileResource.toString() },
        { text: "Dashboards", id: DashboardResource.toString() },
        { text: "Edit Chart", id: "Edit Chart" },
        { text: "Master Exclusion Queue", id: ExclusionQueuesResource.toString() },
        { text: "Master Feedback Queue", id: FeedbackQueuesResource.toString() },
        {
          text: "Quality Audit Review",
          id: "quality audit review",
          items: [
            { text: "QA L1", id: L1AuditResource.toString() },
            { text: "QA L2", id: L2AuditResource.toString() },
            { text: "QA L3", id: L3AuditResource.toString() },
          ]
        },
        { text: "Reject Queue", id: RejectQueuesResource.toString() },
        {
          text: "Reporting",
          id: "reporting",
          items: [
            { text: "Custom Report", id: CustomReportsResource.toString() },
            { text: "Manager Report", id: ManagerReportsResource.toString() },
            { text: "Standard Report", id: StandardReportsResource.toString() }
          ]
        },
        { text: "Reset Chart", id: ResetFunctionalityResource.toString() },
        { text: "Sampling", id: SamplingManagementResource.toString() },
        { text: "Work Queue", id: WorkQueueManagementResource.toString() }
      ]
    }
  ];
  public isEducationManagementDisabled = true;
  public isGuidelinesManagementDisabled = true;
  public isProviderManagementDisabled = true;
  public isUserManagementDisabled = true;
  public isAlertManagementDisabled = true;
  public isCodingReviewDisabled = true;
  public isMasterExclusionQueueDisabled = true;
  public isQALevel1Disabled = true;
  public isQALevel2Disabled = true;
  public isQALevel3Disabled = true;
  public isRejectQueueDisabled = true;
  public isCustomReportingDisabled = true;
  public isStandardReportingDisabled = true;
  public isManagerReportingDisabled = true;
  public isDashboardsDisabled = true;
  public isMasterFeedbackQueueDisabled = true;
  public isCodingProfileDisabled = true;
  public isChartRepositoryDisabled = true;
  public isEditChartDisabled = true;
  public isResetChartDisabled = true;
  public isSamplingDisabled = true;
  public isWorkQueueDisabled = true;
  public disableItems = [];
  public viewMode = false;
  public headerInfo$: Subscription;
  public viewMode$: Subscription;
  public action$: Subscription;
  public selectClient$: Subscription;
  public clientList;
  public client$: Subscription;
  public userId: number;
  public clientId: number;
  public clientData: IClient;
  public activeClientData$: Subscription;
  public mailsList;
  public mails$: Subscription;
  public subscription: Subscription;
  public alertsSubscription: Subscription;
  public alerts$: Subscription;
  public alertIconColor: string;
  public permissionClient$: Subscription;
  public userPermissions$: Subscription;
  public loggedInUserPermission;
  public loggedInUserData;
  public userManagementPermissionFound = false;
  public alertPermissionFound = false;
  public chartRepositoryPermissionsFound = false;
  public codingProfilePermissionsFound = false;
  public firstName: string;
  public lastName: string;
  public username: string;
  public isUserExclusionDisabled = true;
  public isLockChartDisabled = true;
  public isUserProductivityDisabled = true;
  public isUserFeedbackDisabled = true;
  public systemAlerts$: Subscription;
  idleState = "NOT_STARTED";
  private lastPing: Date;
  public resourceArray = [];
  public pdf$: Subscription;
  public chartWindow: Window;
  public chartWindowPDF$: Subscription;
  public selectedProjectType: number;
  public blindCodingProject = BlindCoding;
  public codeDataValidationProject = CodeDataValidation;
  public vendorAuditProject = VendorAudit;
  public isProviderQueriesDisabled: boolean = true;
  public isProviderDashboardDisabled: boolean = true;
  public userRoleId: number
  concurrentProject = Concurrent;
  public hasConcurrentProject:Observable<boolean> = this.selectProjectService.hasClientProjectConcurrent$; 

  private routerSubscription$:Subscription;
  private resetChartForDemoObj = { text: "Reset Charts for Demo", id: 'resetChartsForDemo' };

  constructor(private router: Router, private dataService: DataService, private location: LocationStrategy,
    public dialog: MatDialog, private communicatorService: CommunicatorService,
    public selectClientService: SelectClientService,
    private extensionService:ExtensionService,
    public systemClients: SystemClientsService, public mailboxService: MailboxService,
    private applicationConfig: ConfigurationService,
    public listAlertsService: ListAlertsService, public timezoneService: TimezoneService,
    public userService: UserService, private notificationService: NotificationService,
    private errorMessageService: ErrorMessageService,
    private idle: Idle, private keepalive: Keepalive, private reportsService: ReportsService, private activatedRoute: ActivatedRoute, private cookieService: CookieService,
    private selectProjectService:SelectProjectService) {
    
    /** 
     * Handle the initial route logic i.e invoking handleRouterChangeEvent function when the component is loaded
     * because the navigation happens before this component is initialized and Router.events does not emit any event for the initial navigation.
     * */ 
    this.handleRouterChangeEvent();

    this.routerSubscription$ = this.router.events.pipe(
      filter((event=>event instanceof NavigationEnd)))
      .subscribe(() => {
      this.handleRouterChangeEvent();
    });
  }

  ngOnInit() {
    this.setUserRole()
    this.conditionallyAddResetChartsForDemoToMenu()
    
    // Show inactivity popup to user when user is idle for (SessionExpiredTime) seconds.
    this.idle.setIdle(SessionExpiredTime);
    this.idle.setAutoResume(AutoResume.notIdle);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleStart.subscribe(() => {
      if (!this.location.path().includes("/session/signin")) {
        this.dialog.open(SessionExpiredComponent, {
          disableClose: true,
          width: "500px",
          data: {}
        });
      }
    });
    this.keepalive.interval(SessionExtendTime);
    this.keepalive.onPing.subscribe(() => {
      this.lastPing = new Date();
      this.communicatorService.postData(EndPointBaseUrl + API_CURRENT_VERSION + "/session/extend", null).subscribe();
    });
    this.reset();
    this.mails$ = this.mailboxService.mailsList$.subscribe(data => {
      if (data !== undefined && data !== null) {
        this.mailsList = data;
      }
    });
    this.resourceArray.push(CodingReviewResource, L1AuditResource, L2AuditResource, L3AuditResource);
    this.checkPreCondition();
    this.headerInfo$ = this.dataService.chartHeaderInfo$.subscribe(data => {
      if (data !== undefined && data !== null) {
        this.chartData = data;
      } else {
        this.chartData = null;
      }
    });
    this.viewMode$ = this.dataService.viewMode$.subscribe(data => {
      if (data !== undefined && data !== null) {
        this.viewMode = data;
      }
    });
    this.action$ = this.dataService.action$.subscribe(data => {
      if (data !== undefined && data.action !== undefined && (data.action === "logOut")) {
        this.logOut();
      }
    });
    this.selectClient$ = this.selectClientService.selectedClientData$.subscribe(data => {
      if (data !== undefined && data !== null) {
        this.clientData = data;
        this.selectedClientName = this.clientData?.name || null;
        this.disableAllMenuBasedOnClientPermissions();
        this.disableAllMenuBasedOnProjectPermissions();
        this.refreshMenu();
      }
    });
    this.client$ = this.systemClients.clientListOfLoggedInUser$.subscribe(data => {
      if (data !== undefined && data !== null && data.length > 0) {
        this.clientList = data;
        if (this.clientId == null) {
          this.selectedClientName = null;
        }
      }
    });
    this.alerts$ = this.listAlertsService.allActiveAlertsList$.subscribe(data => {
      if (data !== undefined && data !== null) {
        this.alertsList = data;
        this.alertIconColor = data[0].priority.name;
      }
    });
    this.systemAlerts$ = this.listAlertsService.allActiveSystemAlertsList$.subscribe(data => {
      if (data !== undefined && data !== null) {
        this.alertsList = data;
        this.alertIconColor = data[0].priority.name;
      }
    });
    if (this.hideIcons === false) {
      const source = interval(this.applicationConfig.requestInEverySecond);
      this.subscription = source.subscribe(val => this.getMailNotifications(this.clientId));
      const alertSource = interval(this.applicationConfig.requestInEverySecond);
      this.alertsSubscription = alertSource.subscribe(val => this.getAlertsOfActiveClient(this.clientId));
    }
    this.pdf$ = this.dataService.pdfData$.subscribe(data => {
      if (data !== undefined && data !== null) {
        this.pdfWindow = data;
      }
    });
    window.addEventListener("offline", () => {
      this.logoutCleanup();
    });
    this.chartWindowPDF$ = this.dataService.chartWindowRef$.subscribe(data => {
      if (data !== undefined && data !== null) {
        this.chartWindow = data;
      }
    })

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['projectType'] !== undefined && params['projectType'] !== null && isNaN(params['projectType']) === false) {
        this.selectedProjectType = Number(params['projectType']);
      }
    })
  }

  conditionallyAddResetChartsForDemoToMenu(){
    if(!(environment.production) && (this.userRoleId === ProductOwner)){
      this.menuItems[0].items.push(this.resetChartForDemoObj);
    }
  }

  setUserRole(){
    const userRoleString = sessionStorage.getItem('role') 
    this.userRoleId = RoleStringToIdMapping[userRoleString]
  }

  getMailNotifications(clientId) {
    if (clientId !== undefined && clientId !== null && !isNaN(clientId)) {
      this.mailboxService.getMailNotifications(1, clientId);
    }
  }

  checkPreCondition() {
    const userId = sessionStorage.getItem("userId");
    this.userId = Number(userId)    
    const clientId = sessionStorage.getItem("activeClient");
    this.userPermissions$ = this.userService.loggedInUserPermissions$.subscribe(data => {
      if (data !== undefined && data !== null) {
        this.loggedInUserPermission = data;
        this.firstName = this.loggedInUserPermission.firstName;
        this.lastName = this.loggedInUserPermission.lastName;
        this.username = this.loggedInUserPermission.username;

        for (const clientIndex in this.loggedInUserPermission.clients) {
          for (const clientPermissions in this.loggedInUserPermission.clients[clientIndex].permissions) {
            for (const subPermissions in this.loggedInUserPermission.clients[clientIndex].permissions[clientPermissions].subResources) {
              if (this.loggedInUserPermission.clients[clientIndex].permissions[clientPermissions].subResources[subPermissions].id === UserManagementResource) {
                this.userManagementPermissionFound = true;
                break;
              }
            }
          }
        }

        for (const clientIndex in this.loggedInUserPermission.clients) {
          for (const clientPermissions in this.loggedInUserPermission.clients[clientIndex].permissions) {
            if (this.loggedInUserPermission.clients[clientIndex].permissions[clientPermissions].id === AlertsResource) {
              this.alertPermissionFound = true;
              break;
            }
          }
        }

        for (const clientIndex in this.loggedInUserPermission.clients) {
          for (const projectIndex in this.loggedInUserPermission.clients[clientIndex].projects) {
            for (const projectPermissions in this.loggedInUserPermission.clients[clientIndex].projects[projectIndex].permissions) {
              for (const subPermissions in this.loggedInUserPermission.clients[clientIndex].projects[projectIndex].permissions[projectPermissions].subResources) {
                if (this.loggedInUserPermission.clients[clientIndex].projects[projectIndex].permissions[projectPermissions].subResources[subPermissions].id === ChartRepositoryResource) {
                  this.chartRepositoryPermissionsFound = true;
                  break;
                }
              }
            }
          }
        }

        for (const clientIndex in this.loggedInUserPermission.clients) {
          for (const projectIndex in this.loggedInUserPermission.clients[clientIndex].projects) {
            for (const projectPermissions in this.loggedInUserPermission.clients[clientIndex].projects[projectIndex].permissions) {
              for (const subPermissions in this.loggedInUserPermission.clients[clientIndex].projects[projectIndex].permissions[projectPermissions].subResources) {
                if (this.loggedInUserPermission.clients[clientIndex].projects[projectIndex].permissions[projectPermissions].subResources[subPermissions].id === CodingProfileResource) {
                  this.codingProfilePermissionsFound = true;
                  break;
                }
              }
            }
          }
        }
        this.disableAllMenuBasedOnClientPermissions();
        this.disableAllMenuBasedOnProjectPermissions();
        this.refreshMenu();
      }
    });
    this.userService.getLoggedInPermissions();
    if (userId === undefined || userId === null) {
      this.router.navigate(["/session/signin"]);
    } else if (clientId === undefined || clientId === null) {
      this.timezoneService.getTimezoneList();
      this.router.navigate(["/client"]);
      this.getSystemAlerts();
    } else {
      this.clientId = Number(clientId);
      this.selectClientService.getClientData(this.clientId);
      if (!isNaN(this.clientId)) {
        this.mailsList = [];
        this.alertsList = [];
        this.getAlertsOfActiveClient(this.clientId);
        this.getMailNotifications(this.clientId);
      }
      this.systemClients.getClientListOfLoggedInUser(true);
    }
    this.embedPowerBIUserProductivityReport();
  }

  disableAllMenuBasedOnProjectPermissions() {
    this.disableItems = [];
    this.isCodingReviewDisabled = true;
    this.isMasterExclusionQueueDisabled = true;
    this.isQALevel1Disabled = true;
    this.isQALevel2Disabled = true;
    this.isQALevel3Disabled = true;
    this.isRejectQueueDisabled = true;
    this.isCustomReportingDisabled = true;
    this.isStandardReportingDisabled = true;
    this.isManagerReportingDisabled = true;
    this.isDashboardsDisabled = true;
    this.isMasterFeedbackQueueDisabled = true;
    this.isCodingProfileDisabled = true;
    this.isChartRepositoryDisabled = true;
    this.isEditChartDisabled = true;
    this.isResetChartDisabled = true;
    this.isSamplingDisabled = true;
    this.isWorkQueueDisabled = true;
    this.isUserExclusionDisabled = true;
    this.isLockChartDisabled = true;
    this.isUserProductivityDisabled = true;
    this.isUserFeedbackDisabled = true;
    this.isProviderDashboardDisabled = true;
    this.isProviderQueriesDisabled = true;
  }

  disableAllMenuBasedOnClientPermissions() {
    this.disableItems = [];
    this.isEducationManagementDisabled = true;
    this.isGuidelinesManagementDisabled = true;
    this.isProviderManagementDisabled = true;
    this.isUserManagementDisabled = true;
    this.isAlertManagementDisabled = true;
  }

  setHamburgerMenuClientUniquePermissions(permissions) {
    for (const adminClientPermission in permissions) {
      const adminClientPermissionItem = permissions[adminClientPermission].id;
      if (adminClientPermissionItem === EducationResource) {
        this.isEducationManagementDisabled = false;
      }
      if (adminClientPermissionItem === GuidelineResource) {
        this.isGuidelinesManagementDisabled = false;
      }
      if (adminClientPermissionItem === ProviderResource) {
        this.isProviderManagementDisabled = false;
      }
      if (adminClientPermissionItem === UserManagementResource) {
        this.isUserManagementDisabled = false;
      }
      if (adminClientPermissionItem === AlertsResource) {
        this.isAlertManagementDisabled = false;
      }
    }
    
    if (this.alertPermissionFound) {
      this.isAlertManagementDisabled = false;
    }
    this.hideItemsFromMenuBasedOnClientPermissions();
  }

  setHamburgerMenuProjectUniqueConditions(permissions) {
    for (const clientPermission in permissions) {
      const clientPermissionItem = permissions[clientPermission].id;
      if (clientPermissionItem === CodingReviewResource) {
        this.isCodingReviewDisabled = false;
      }
      if (clientPermissionItem === ExclusionQueuesResource) {
        this.isMasterExclusionQueueDisabled = false;
      }
      if (clientPermissionItem === L1AuditResource) {
        this.isQALevel1Disabled = false;
      }
      if (clientPermissionItem === L2AuditResource) {
        this.isQALevel2Disabled = false;
      }
      if (clientPermissionItem === L3AuditResource) {
        this.isQALevel3Disabled = false;
      }
      if (clientPermissionItem === RejectQueuesResource) {
        this.isRejectQueueDisabled = false;
      }
      if (clientPermissionItem === FeedbackQueuesResource) {
        this.isMasterFeedbackQueueDisabled = false;
      }
      if (clientPermissionItem === CodingProfileResource) {
        this.isCodingProfileDisabled = false;
      }
      if (clientPermissionItem === ChartRepositoryResource) {
        this.isChartRepositoryDisabled = false;
      }
      if (clientPermissionItem === DashboardResource) {
        this.isDashboardsDisabled = false;
      }
      if (clientPermissionItem === StandardReportsResource) {
        this.isStandardReportingDisabled = false;
      }
      if (clientPermissionItem === CustomReportsResource) {
        this.isCustomReportingDisabled = false;
      }
      if (clientPermissionItem === ManagerReportsResource) {
        this.isManagerReportingDisabled = false;
      }
      if (clientPermissionItem === EditChartResource) {
        this.isEditChartDisabled = false;
      }
      if (clientPermissionItem === ResetFunctionalityResource) {
        this.isResetChartDisabled = false;
      }
      if (clientPermissionItem === SamplingManagementResource) {
        this.isSamplingDisabled = false;
      }
      if (clientPermissionItem === WorkQueueManagementResource) {
        this.isWorkQueueDisabled = false;
      }
      // if (clientPermissionItem === ProviderQueriesResource) {
      //   this.isProviderQueriesDisabled = false;
      // }
      // if (clientPermissionItem === Non_EHR_Dashboard) {
      //   this.isProviderDashboardDisabled = false;
      // }
    }
    this.hideItemsFromMenuBasedOnProjectPermissions();
  }

  set9DotMenuConditions(loggedInUserPermission) {

    if (loggedInUserPermission !== undefined && loggedInUserPermission !== null) {
      for (const permission in loggedInUserPermission) {
        const permissionItem = loggedInUserPermission[permission].id;

        if (
          (permissionItem === CodingReviewResource) ||
          (permissionItem === L1AuditResource) ||
          (permissionItem === L2AuditResource) ||
          (permissionItem === L3AuditResource) || 
          (permissionItem === ProviderQueriesResource)
        ) {
            this.isUserExclusionDisabled = false;
            this.isLockChartDisabled = false;
            this.isUserProductivityDisabled = false;
            this.isUserFeedbackDisabled = false;
            this.isProviderQueriesDisabled = false
        }
      }
    }
  }


  private beforeOpen($event: BeforeOpenCloseMenuEventArgs) {
    // tslint:disable-next-line:prefer-for-of
    for (const i in $event.items) {
      if (this.disableItems.indexOf($event.items[i].text) > -1) {
        this.menuObj.hideItems([$event.items[i].text], false);
      }
    }
    //for showing and hiding reset chart demo menu
    if(!environment.production && this.userRoleId === ProductOwner){
      const showResetChartDemoMenu = this.selectProjectService.hasClientProjectConcurrentSubjectValue;
      showResetChartDemoMenu ? this.menuObj.showItems([this.resetChartForDemoObj.text],false) : this.menuObj.hideItems([this.resetChartForDemoObj.text],false);
    }
  }

  private created(): void {
    // Disable menu items
    const items = [];
    for (let i = 0; i < this.disableItems.length; i++) {
      const li = document.getElementById(this.disableItems[i]);
      if (li) {
        items.push(li.innerText);
      }
    }
    this.menuObj.hideItems(items, false);
  }

  getAlertsOfActiveClient(clientId) {
    if (clientId !== undefined && clientId !== null && !isNaN(clientId)) {
      this.listAlertsService.getAllActiveAlertsList(CommonAlertStatus.Active, clientId);
      this.alerts$ = this.listAlertsService.allActiveAlertsList$.subscribe(data => {
        if (data !== undefined && data !== null) {
          this.alertsList = [];
          this.alertsList = data;
          this.alertIconColor = this.alertsList[0].priority.name;
        }
      });
    }
  }

  getSystemAlerts() {
    if (this.hideIcons === true) {
      this.listAlertsService.getSystemAlerts(CommonAlertStatus.Active, true);
      this.systemAlerts$ = this.listAlertsService.allActiveSystemAlertsList$.subscribe(data => {
        if (data !== undefined && data !== null) {
          this.alertsList = [];
          this.alertsList = data;
          this.alertIconColor = this.alertsList[0].priority.name;
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.headerInfo$) {
      this.headerInfo$.unsubscribe();
    }
    if (this.userPermissions$) {
      this.userPermissions$.unsubscribe();
    }
    if (this.viewMode$) {
      this.viewMode$.unsubscribe();
    }
    if (this.action$) {
      this.action$.unsubscribe();
    }
    if (this.selectClient$) {
      this.selectClient$.unsubscribe();
    }
    if (this.client$) {
      this.client$.unsubscribe();
    }
    if (this.mails$) {
      this.mails$.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.alertsSubscription) {
      this.alertsSubscription.unsubscribe();
    }
    if (this.alerts$) {
      this.alerts$.unsubscribe();
    }
    if (this.permissionClient$) {
      this.permissionClient$.unsubscribe();
    }
    if (this.systemAlerts$) {
      this.systemAlerts$.unsubscribe();
    }
    if (this.pdf$) {
      this.pdf$.unsubscribe();
    }
    if (this.chartWindowPDF$) {
      this.chartWindowPDF$.unsubscribe();
    }
    if(this.routerSubscription$) {
      this.routerSubscription$.unsubscribe();
    }
    this.dialog.closeAll();
  }

  // openChartPDF(chartData) {
  //   if (chartData !== undefined && chartData !== null &&
  //     chartData.pdf !== undefined && chartData.pdf !== null) {
  //     const documentLink = chartData.pdf;
  //     this.pdfWindow = window.open(documentLink + '#toolbar=0', "chart-pdf",
  //       "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,\n" +
  //       "width=0,height=0,left=-1000,top=-1000");
  //     this.dataService.sharePDF(this.pdfWindow);
  //   }
  // }

  openChartPDF(chartData) {
    if (chartData?.id !== undefined && chartData?.id !== null) {
      let queryParams = {};

      queryParams["chartId"] = chartData.id;
      queryParams["showHeader"] = false;
      queryParams = queryParams || {};
      let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
      if (queryString !== "") {
        queryString = "?" + queryString;
      }

      this.pdfWindow = window.open("/pdfviewdefault" + queryString, "PDFDocument",
        "width=1400,height=1000,top=20,left=20,location = 0,resizable=0,scrollbars=1,toolbar=0,menubar=0,status=0,titlebar=0");
      this.dataService.sharePDF(this.pdfWindow);
    }
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
  }

  signOut() {
    if ((this.location.path().includes("/coding/v2") || this.location.path().includes("/coding-concurrent/v2")) && (this.chartData !== undefined && this.chartData !== null)) {
      this.openCommonDialogPopup("You are logging out, any data will be lost. Do you wish to continue?", true, "logOut", "/session/logout");
    } else {
      this.logOut();
    }
  }

  logOut() {
    this.communicatorService.postData(EndPointBaseUrl + API_CURRENT_VERSION + "/session/logout", null).subscribe(signOut => {
      if (signOut === "User logged out successfully") {
        this.logoutCleanup();
        window.location.reload();
      } else {
        this.router.navigate([""]);
      }
    }, errors => {
      const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBar(errors);
      if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
        this.notificationService.showError(manipulatedErrorMessage);
      }
    });
  }

  logoutCleanup(){
      if (this.pdfWindow) {
        this.pdfWindow.close();
      }
      if (this.chartWindow) {
        this.chartWindow.close();
      }
      sessionStorage.setItem("authenticated", String(false));
      localStorage.clear();
      this.extensionService.sendUserLogout();
      Temp.signout = true;
      this.dialog.closeAll();
      this.cookieService.removeAll({
        path: "/",
        domain: environment.COOKIE_DOMAIN,
      });
      this.router.navigate(["/session/signin"]);
  }

  openCommonDialogPopup(message, type, actionPerformed, nextRoute?) {
    if (message !== "") {
      this.dialog.open(CommonDialogComponent, {
        disableClose: true,
        data: {messages: message, confirmation: type, action: actionPerformed, nextState: nextRoute}
      }).afterClosed().subscribe(value => {
        switch(value.event){
          case AthenaResetChartEvents.Success:
            this.notificationService.showSuccess('Athena demo charts have been reset successfully!')
            break;
          case AthenaResetChartEvents.Error:
            this.notificationService.showError(value.message)
            break;
          default:
            break;
        }
      });
    }
  }

  routeToProviderQueries(){
    if (!this.isProviderQueriesDisabled) {
      localStorage.setItem("menuItem", "Provider Queries")
      sessionStorage.removeItem("masterFeedback.filter");
      this.router.navigate(["/coding-concurrent/provider-queries"]);
    }
  }

  routeToProvidersDashboard(role:number){
    sessionStorage.setItem("roleId", String(role));
    localStorage.setItem("menuItem", "QALevel-3");
    this.router.navigate(["/project"], {
      queryParams: {
        roleId: role
      }});
  }

  routeToProject(role) {
    sessionStorage.removeItem("masterFeedback.filter");
    sessionStorage.setItem("roleId", String(role));
    localStorage.setItem("menuItem", "Coder");
    this.router.navigate(["/project"], {
      queryParams: {
        roleId: role
      }
    });
  }

  routeToLockedCharts() {
    if (!this.isLockChartDisabled) {
      sessionStorage.removeItem("masterFeedback.filter");
      this.router.navigate(["/locked-charts"]);
    }
  }

  routeToExclusionQueue() {
    sessionStorage.removeItem("masterFeedback.filter");
    this.router.navigate(["/project-exclusion-queue"]);
  }

  routeToPersonalExclusion() {
    if (!this.isUserExclusionDisabled) {
      sessionStorage.removeItem("masterFeedback.filter");
      this.router.navigate(["/exclusion-queue-response"]);
    }
  }

  routeToRejectQueue() {
    sessionStorage.removeItem("masterFeedback.filter");
    this.router.navigate(["/reject-queue"]);
  }

  routeToFeedbackQueue() {
    if (!this.isUserFeedbackDisabled) {
      sessionStorage.removeItem("masterFeedback.filter");
      this.router.navigate(["/feedback-queue"]);
    }
  }

  routeToEditChart() {
    sessionStorage.removeItem("masterFeedback.filter");
    this.router.navigate(["/edit-chart"]);
  }

  routeToResetChart() {
    sessionStorage.removeItem("masterFeedback.filter");
    this.router.navigate(["/coding/reset-chart-list"]);
  }

  routeToQALevel1(role) {
    sessionStorage.removeItem("masterFeedback.filter");
    sessionStorage.setItem("roleId", String(role));
    localStorage.setItem("menuItem", "QALevel-1");
    this.router.navigate(["/project"], {
      queryParams: {
        roleId: role
      }
    });
  }

  routeToQALevel2(role) {
    sessionStorage.removeItem("masterFeedback.filter");
    sessionStorage.setItem("roleId", String(role));
    localStorage.setItem("menuItem", "QALevel-2");
    this.router.navigate(["/project"], {
      queryParams: {
        roleId: role
      }
    });
  }

  routeToQALevel3(role) {
    sessionStorage.removeItem("masterFeedback.filter");
    sessionStorage.setItem("roleId", String(role));
    localStorage.setItem("menuItem", "QALevel-3");
    this.router.navigate(["/project"], {
      queryParams: {
        roleId: role
      }
    });
  }

  routeToMasterFeedbackQueue() {
    sessionStorage.removeItem("masterFeedback.filter");
    this.router.navigate(["/master-feedback-queue"]);
  }

  openCodingGuidelinesPopup() {
    this.dialog.open(CodingGuidelinesDialogComponent, {
      width: "850px",
      disableClose: true
    });
  }

  openEducationPopup() {
    this.dialog.open(EducationDialogComponent, {
      width: "850px",
      disableClose: true
    });
  }

  routeToDashboard(resourceId) {
    sessionStorage.removeItem("masterFeedback.filter");
    sessionStorage.setItem("resourceId", String(resourceId));
    localStorage.setItem("menuItem", "Dashboards");
    this.router.navigate(["/project/dashboard"]);
  }

  routeToUserProductivityReport() {
    sessionStorage.removeItem("masterFeedback.filter");
    this.reportsService.getUserProductivityThroughPowerBiIntegration(this.resourceArray,
      this.clientId, UserProductivityReport);
  }

  routeToProviderList() {
    sessionStorage.removeItem("masterFeedback.filter");
    this.router.navigate(["/provider/list"]);
  }

  routeToUserManagement() {
    sessionStorage.removeItem("masterFeedback.filter");
    this.router.navigate(["/users/list"]);
  }

  routeToGuidelinesManagement() {
    sessionStorage.removeItem("masterFeedback.filter");
    this.router.navigate(["/guidelines/list"]);
  }

  routeToEducationManagement() {
    sessionStorage.removeItem("masterFeedback.filter");
    this.router.navigate(["/education/list"]);
  }

  getSelectedMenu(event) {
    sessionStorage.removeItem("editChart.filter");
    if (event.item.properties.id === CodingReviewResource.toString()) {
      this.routeToProject(CoderRole);
    }
    if (event.item.properties.text === "Master Exclusion Queue") {
      this.routeToExclusionQueue();
    }
    if (event.item.properties.id === L1AuditResource.toString()) {
      this.routeToQALevel1(QAL1Role);
    }
    if (event.item.properties.id === L2AuditResource.toString()) {
      this.routeToQALevel2(QAL2Role);
    }
    if (event.item.properties.id === L3AuditResource.toString()) {
      this.routeToQALevel3(QAL3Role);
    }
    if (event.item.properties.text === "Reject Queue") {
      this.routeToRejectQueue();
    }
    if (event.item.properties.text === "Master Feedback Queue") {
      this.routeToMasterFeedbackQueue();
    }
    if (event.item.properties.id === DashboardResource.toString()) {
      this.routeToDashboard(DashboardResource);
    }
    if (event.item.properties.text === "Provider Management") {
      this.routeToProviderList();
    }
    if (event.item.properties.text === "User Management") {
      this.routeToUserManagement();
    }
    if (event.item.properties.text === "Guidelines Management") {
      this.routeToGuidelinesManagement();
    }
    if (event.item.properties.text === "Education Management") {
      this.routeToEducationManagement();
    }
    if (event.item.properties.text === "Alerts") {
      this.routeToAlertsManagement();
    }
    if (event.item.properties.text === "Edit Chart") {
      this.routeToEditChart();
    }
    if (event.item.properties.text === "Reset Chart") {
      this.routeToResetChart();
    }
    if (event.item.properties.text === "Coding Profile") {
      this.routeToCodingProfile();
    }
    if (event.item.properties.text === "Chart Repository") {
      this.routeToChartRepository();
    }
    if (event.item.properties.text === "Sampling") {
      this.routeToSampling();
    }
    if (event.item.properties.text === "Work Queue") {
      this.routeToWorkQueue();
    }
    if (event.item.properties.id === StandardReportsResource.toString()) {
      this.routeToStandardReport(StandardReportsResource);
    }
    if (event.item.properties.id === ManagerReportsResource.toString()) {
      this.routeToManagerReport(ManagerReportsResource);
    }
    if (event.item.properties.id === CustomReportsResource.toString()) {
      this.routeToCustomReport(CustomReportsResource);
    }
    if (event.item.properties.id === ProviderQueriesResource.toString()) {
      this.routeToProviderQueries();
    }
    if (event.item.properties.id === ProvidersDashboardResource.toString()) {
      this.routeToProvidersDashboard(ProviderRole);
    }
    if(event.item.properties.id === 'resetChartsForDemo'){
      this.resetChartForDemo()
    }
  }

  resetChartForDemo(){
    this.openCommonDialogPopup("This action will reset all charts in Athena to inital state. Do you want to continue?", true, "resetForDemo");
  }

  routeToCustomReport(resourceId) {
    sessionStorage.removeItem("masterFeedback.filter");
    sessionStorage.setItem("resourceId", String(resourceId));
    localStorage.setItem("menuItem", "Custom Report");
    this.router.navigate(["/project/custom-report"]);
  }

  routeToManagerReport(resourceId) {
    sessionStorage.removeItem("masterFeedback.filter");
    sessionStorage.setItem("resourceId", String(resourceId));
    localStorage.setItem("menuItem", "Manager Report");
    this.router.navigate(["/project/manager-report"]);
  }

  routeToStandardReport(resourceId) {
    sessionStorage.removeItem("masterFeedback.filter");
    sessionStorage.setItem("resourceId", String(resourceId));
    localStorage.setItem("menuItem", "Standard Report");
    this.router.navigate(["/project/standard-report"]);
  }

  routeToSampling() {
    sessionStorage.removeItem("masterFeedback.filter");
    this.router.navigate(["/project/sampling-configuration"]);
  }

  routeToWorkQueue() {
    sessionStorage.removeItem("masterFeedback.filter");
    this.router.navigate(["/project/work-queue"]);
  }


  openManageProfileSectionDialog(event) {
    this.dialog.open(ModifyUserProfileComponent, {
      disableClose: true,
      width: "400px",
    });
  }

  switchClient(event) {
    if (event !== undefined && event !== null) {
      if (this.clientList !== undefined && this.clientList !== null) {
        if (this.clientList.length === 1) {
          const clientId = this.clientList[0].id;
          sessionStorage.setItem("activeClient", clientId);
          if (clientId !== undefined && clientId !== null) {
            this.selectClientService.getClientData(clientId);
            this.router.navigate(["/"]);
            this.getAlertsOfActiveClient(this.clientId);
          }
        } else {
          this.router.navigate(["/client"]);
          this.hideIcons = true;
          this.clientId = null;
          this.getSystemAlerts();
          sessionStorage.removeItem("masterFeedback.filter");
        }
      }
    }
  }

  routeToAlertsManagement() {
    sessionStorage.removeItem("masterFeedback.filter");
    this.router.navigate(["/alerts/list"]);
  }

  routeToCodingProfile() {
    sessionStorage.removeItem("masterFeedback.filter");
    this.router.navigate(["/reports/coding-profile-report"]);
  }

  routeToChartRepository() {
    sessionStorage.removeItem("masterFeedback.filter");
    this.router.navigate(["/chart-repository/list"]);
  }

  refreshMenu() {
    if (this.loggedInUserPermission !== undefined && this.loggedInUserPermission !== null) {
      this.disableAllMenuBasedOnClientPermissions();
      this.disableAllMenuBasedOnProjectPermissions();
      for (const clientIndex in this.loggedInUserPermission.clients) {
        if (this.clientId === this.loggedInUserPermission.clients[clientIndex].id) {
          const selectedClientPermissions = this.loggedInUserPermission.clients[clientIndex].clientUniquePermission;
          this.setHamburgerMenuClientUniquePermissions(selectedClientPermissions);
          if (this.loggedInUserPermission.clients[clientIndex].projects.length > 0) {
            const selectedProjectPermissions = this.loggedInUserPermission.clients[clientIndex].projectUniquePermission;
            if (selectedProjectPermissions !== undefined && selectedProjectPermissions !== null) {
              if (this.codingProfilePermissionsFound) {
                selectedProjectPermissions.push({
                  name: "Coding Profile",
                  id: CodingProfileResource
                });
              }
              if (this.chartRepositoryPermissionsFound) {
                selectedProjectPermissions.push({
                  name: "Chart Repository",
                  id: ChartRepositoryResource
                });
              }
              this.setHamburgerMenuProjectUniqueConditions(selectedProjectPermissions);
              this.set9DotMenuConditions(selectedProjectPermissions);
            }
          } else {
            const selectedProjectPermissions = [];
            if (this.codingProfilePermissionsFound) {
              selectedProjectPermissions.push({
                name: "Coding Profile",
                id: CodingProfileResource
              });
            }
            if (this.chartRepositoryPermissionsFound) {
              selectedProjectPermissions.push({
                name: "Chart Repository",
                id: ChartRepositoryResource
              });
            }
            this.disableAllMenuBasedOnProjectPermissions();
            this.setHamburgerMenuProjectUniqueConditions(selectedProjectPermissions);
            this.setHamburgerMenuClientUniquePermissions(selectedClientPermissions);
            this.hideItemsFromMenuBasedOnProjectPermissions();
          }
        }
      }
      this.firstName = this.loggedInUserPermission.firstName;
      this.lastName = this.loggedInUserPermission.lastName;
      this.username = this.loggedInUserPermission.username;
    }

  }

  hideItemsFromMenuBasedOnProjectPermissions() {
    if (this.isCodingReviewDisabled) {
      this.disableItems.push("Coder");
    }
    if (this.isMasterExclusionQueueDisabled) {
      this.disableItems.push("Master Exclusion Queue");
    }
    if (this.isQALevel1Disabled) {
      this.disableItems.push("QA L1");
    }
    if (this.isQALevel2Disabled) {
      this.disableItems.push("QA L2");
    }
    if (this.isQALevel3Disabled) {
      this.disableItems.push("QA L3");
    }
    if (this.isRejectQueueDisabled) {
      this.disableItems.push("Reject Queue");
    }
    if (this.isMasterFeedbackQueueDisabled) {
      this.disableItems.push("Master Feedback Queue");
    }
    if (this.isCodingProfileDisabled) {
      this.disableItems.push("Coding Profile");
    }
    if (this.isStandardReportingDisabled) {
      this.disableItems.push("Standard Report");
    }
    if (this.isCustomReportingDisabled) {
      this.disableItems.push("Custom Report");
    }
    if (this.isManagerReportingDisabled) {
      this.disableItems.push("Manager Report");
    }
    if (this.isDashboardsDisabled) {
      this.disableItems.push("Dashboards");
    }
    if (this.isChartRepositoryDisabled) {
      this.disableItems.push("Chart Repository");
    }
    if (this.isEditChartDisabled) {
      this.disableItems.push("Edit Chart");
    }
    if (this.isResetChartDisabled) {
      this.disableItems.push("Reset Chart");
    }
    if (this.isSamplingDisabled) {
      this.disableItems.push("Sampling");
    }
    if (this.isWorkQueueDisabled) {
      this.disableItems.push("Work Queue");
    }
    if (this.isStandardReportingDisabled && this.isManagerReportingDisabled && this.isCustomReportingDisabled) {
      this.disableItems.push("Reporting");
    }
    if (this.isQALevel1Disabled && this.isQALevel2Disabled && this.isQALevel3Disabled) {
      this.disableItems.push("Quality Audit Review");
    }
    if (this.isProviderQueriesDisabled) {
      this.disableItems.push("Provider Queries");
    }
    if (this.isProviderDashboardDisabled) {
      this.disableItems.push("Provider Dashboard");
    }
  }

  hideItemsFromMenuBasedOnClientPermissions() {
    if (this.isEducationManagementDisabled) {
      this.disableItems.push("Education Management");
    }
    if (this.isGuidelinesManagementDisabled) {
      this.disableItems.push("Guidelines Management");
    }
    if (this.isUserManagementDisabled) {
      this.disableItems.push("User Management");
    }
    if (this.isProviderManagementDisabled) {
      this.disableItems.push("Provider Management");
    }
    if (this.isAlertManagementDisabled) {
      this.disableItems.push("Alerts");
    }
    if (this.isUserManagementDisabled && this.isEducationManagementDisabled &&
      this.isGuidelinesManagementDisabled &&  (this.isUserManagementDisabled || this.isProviderManagementDisabled)) {
      this.disableItems.push("Admin");
    }
  }


  embedPowerBIUserProductivityReport() {
    this.reportsService.userProductivityDataForPowerBiIntegration$.subscribe(res => {
      let queryParams = "?";
      if (res.accessToken !== undefined && res.accessToken !== null) {
        queryParams = queryParams + "accessToken=" + res.accessToken + "&";
      }
      if (res.reportConfig[0].embedUrl !== undefined && res.reportConfig[0].embedUrl !== null) {
        queryParams = queryParams + "embedUrl=" + res.reportConfig[0].embedUrl + "&";
      }
      if (res.reportConfig[0].reportId !== undefined && res.reportConfig[0].reportId !== null) {
        queryParams = queryParams + "reportId=" + res.reportConfig[0].reportId + "&";
      }
      window.open("/assets/power-bi-report.html" + queryParams, "EPP Report", "height=800");
    });
  }

  private handleRouterChangeEvent():void{
    if (!this.location.path().includes("/coding/v2") && !this.location.path().includes("/coding-concurrent/v2")) {
      this.chartData = null;
    }
    if (this.location.path() === "/client") {
      this.hideIcons = true;
      this.selectedClientName = null;
      this.alertsList = null;
      this.clientData = null;
      this.alertIconColor = "var(--ecp-eclat-orange)";
      this.clientIdAvailable = true;
    } else {
      this.hideIcons = false;
      if (this.clientIdAvailable) {
        const clientId = sessionStorage.getItem("activeClient");
        if (clientId !== undefined && clientId !== null) {
          this.clientId = Number(clientId);
        }
        this.clientIdAvailable = false;
      }
    }

    if (this.pdfWindow) {
      this.pdfWindow.close();
    }
  }

}
