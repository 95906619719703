import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { ExtensionService } from '../athena-one-integration/shared/service/extension.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor(private activeRoute: ActivatedRoute, private extensionService: ExtensionService) { }

  error$: Observable<Params>

  ngOnInit(): void {
    this.error$ = this.activeRoute.queryParams.pipe(
      tap((params)=>{
        if(params['msg']==='user_not_found'){
          this.extensionService.setExtensionError(true);
        }
      })
    )
    this.extensionService.sendErrorToExt()
  }

}
